@import "variables";

.notificationContainer {
  display: none;
  background-color: $notificationBarBackground;
  border-bottom: 1px solid silver;
  position: fixed;
  width: 100%;
  height: 45px;
  top: 0px;
  overflow: hidden;
  transition: all .5s ease-in-out;
  z-index: 2000;
  text-align: center;

  @media (min-width: $sm) and (max-width: $lg){
    height: 55px;
  }
  @media (min-width: $xs) and (max-width: $sm){
    height: 75px;
  }

}

.browserSupportCol {
  margin: 10px auto;
  font-size: 14px;
  color: $notificationBarText;

  a,
  a:link,
  a:active,
  a:hover,
  a:visited{
    color: $ihgMango;
  }

  .closeContainer{
    position: relative;
  }

  .closeContainer .closeLink {
    color: $notificationBarText;
    text-decoration: none;
  }

  .closeContainer .closeLink .closeIcon {
    position: relative;
    height: 15px;
    width: 15px;
    top: -1px;
  }

  @media (min-width: $sm) and (max-width: $lg){
    font-size: 15px;
  }
  @media (min-width: $xs) and (max-width: $sm){
    font-size: 15px;
  }
}