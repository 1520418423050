/* IHG - Theme Styles - Hotel List */
/* NOTE: Pulled from 'src\main\webapp\find-hotels\style\_themes.scss' */

// Hotel Themes
@mixin theme($name, $color) {
  $primary: $color;

  $ihgTheme: 'theme-6c';

  .#{$name} {
    --ihg-hotel-brand-color: #{$color};

    .resModuleForm-adultsChildrenRooms .ng-click-active {
      background-color: $primary;
    }

    .searchResults .rateIcon,
    .rateIcon {
      background-color: rgba($primary, 0.95);
    }

    .upsellRateRow .selectionIndicator,
    .upsellRateRow .selectionOption:hover {
      border-top: 5px solid $primary;
    }

    .resModuleForm-searchInput {
      background-color: $primary;
      color: $typographyColor1;
    }

    .roomRateExpandTop,
    .roomRateExpandBottom,
    .hotelFlag {
      background-color: $primary;
    }

    .featured {
      border: 4px solid $primary;
      @media (min-width: 320px) and (max-width: 767px) {
        border: 3px solid $primary;
      }
    }
    .featuredSmooth {
      border: 4px solid $primary;
      @media (min-width: 320px) and (max-width: 767px) {
        border: 3px solid $primary;
      }
      @media (min-width: 768px) {
        border-radius: 15px;
      }
    }

    .badgeIconSVG {
      fill: $primary;
    }

    .rateIconContainer,
    .hsf-apply-btn {
      background-color: $primary;
    }

    div.ctaContainer > ol > li > a {
      background-color: $primary;
      border: 1px solid $primary !important;
    }

    /*res module datepicker*/
    .resModule .inputHighlight {
      outline: solid 2px $primary;
    }

    .ihg-datepicker .dp-btn.dp-btn-primary .ihgcal-dateCellContent {
      background-color: $primary;
    }

    .themeLink {
      cursor: pointer;
      color: $primary;
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: $primary;
      }
    }

    .svg-white {
      svg {
        path {
          stroke: $white;
        }
      }
    }

    .dateInput.editable {
      outline: solid 2px $primary;
    }

    .themeHighlight:hover,
    .themeHighlight:focus {
      background-color: $primary;
      color: white;
    }

    .themeBG {
      background-color: $primary;
    }

    .themeExpandableButton {
      background-color: $primary;
      border: 1px solid $primary;
      color: white;

      &.expanded {
        background-color: inherit;
        color: $ihgGrey;
        border-color: $ihgGrey;
      }

      @media (min-width: 768px) {
        border: 2px solid $primary;

        &.expanded {
          color: $neutralGrey10;
          border-color: $neutralGrey10;
        }
      }
    }

    .link {
      .themeExpandableButton {
        border: none;

        &.expanded {
          background-color: inherit;
          color: $primary;
        }
      }
    }

    .themeBG_with_opacity {
      background-color: rgba($primary, 0.8);
    }

    .activeThemeButton {
      background-color: $primary !important;
      color: white !important;
    }

    .themeBorder {
      border-color: $primary;
    }
    .themeText {
      color: $primary;
    }
    .themeFill {
      fill: $primary;
      .green_engage {
        fill: #00a599; //fallback fill for green engage for iOS
      }
      .breakfast,
      .room-type-icon,
      .free_wifi,
      .pet_friendly,
      .free_internet,
      .wifi_included,
      .free_wifi_karma {
        svg {
          path {
            fill: $primary;
          }
        }
      }
      .radio-icon {
        circle.innerRB {
          fill: $primary;
        }
      }
    }
    .themeStroke {
      stroke: $primary;
    }
    .themeBgOnClick:active {
      background-color: $primary;
    }
    .errorMessageText a {
      cursor: pointer;
      text-decoration: underline;
      color: $primary;

      a:link,
      a:visited,
      a:hover,
      a:active {
        color: $primary;
        text-decoration: underline;
      }
    }

    .rateCategoryHeading.usersPreferences {
      background-color: $primary;
    }

    .themeBorderCTA {
      border: 1px solid $primary;
      outline: 1px solid $primary;
    }

    .themeBorderCTAHover:hover {
      border: 1px solid $primary;
      outline: 1px solid $primary;
    }

    .themeLabelHover:hover .ctaLabel {
      color: $primary;
    }

    .themeUiSwitch {
      .p-inputswitch-checked {
        .p-inputswitch-slider {
          background-color: $primary;
        }
      }
    }

    //This will allow you to have a sizeable, brand-themed, custom radio button using pseudo-elements.
    //It places the radio button over the actual radio. Then it's linked to it using the label.
    //The element structure is:
    // .themeRadio
    //    <input type="radio">
    //    <label for="" class="radio-label"> //The radio overlay is contained in the label
    //        <div class="radio-label-text">
    .themeRadio {
      input[type="radio"] {
        &:checked {
          + .radio-label {
            &:before {
              //sets the properties of the selected 'radio'
              background-color: $primary;
            }
          }
        }
      }
    }

    .theme-selectable-item {
      &:hover, &:focus {
        background-color: $primary;
        color: $typographyColor1;
      }
    }

    p-dropdown {
      .ui-dropdown {
        border-color: $neutralGrey9;
        &:hover {
          border-color: $neutralGrey9;
        }
        border-color: $primary;
        .ui-dropdown-label {
          font-weight: normal;
          color: $darkBlueColor;
        }
        .ui-dropdown-trigger-icon {
          color: $darkBlueColor;
        }
      }
      .ui-dropdown.ui-state-focus {
        border-color: $neutralGrey9;
      }
      .ui-state-highlight {
        background-color: $neutralGrey21;
      }
    }

    .datepicker-field {
      .brand-outline {
        outline: solid 2px $primary;
      }
    }

    .ui-ihg-datepicker-calendar {
      .brand-highlight {
        background-color: $primary;
      }
    }

    hotel-availability {
      .selectHotelCTAText {
        color: $primary;
      }
    }

    .rate-group-tab:hover{
      background-color: rgba(red($primary), green($primary), blue($primary), 0.7);
    }

    .room-rate-filter-container, .discount-container, .switch-tabs {
      .switch-box {
        .switch {
          background: $neutralGrey1;
        }
        .checked {
          @if($name == $ihgTheme) {
            background: $ihgBlue;
          } @else {
            background: $primary;
          }
        }
      }
    }

    .room-rate-filter-modal {
      .filter-switch {
        .switch {
          background: $neutralGrey1;
        }
        .checked {
          @if($name == $ihgTheme) {
            background: $ihgBlue;
          } @else {
            background: $primary;
          }
        }
      }
    }

    .room-package-tab {
      button {
        .button-check {
          svg {
            path {
              @if($name == $ihgTheme) {
                fill: $ihgBlue;
              } @else {
                fill: $primary;
              }
            }
          }
        }
      }
    }

    .hotel-filter-brand-container {
      .brand-container {
        .brand-content {
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .hotel-filter-amenities-container {
      .amenity-box {
        .checkbox-container {
          .check-box-icon {
            svg {
              rect {
                stroke: none;
              }
            }
          }
        }
      }
    }
    .hotel-card-container {
      .placeholder-container {
        .brand-logo {
          svg {
            margin: 15px 15px 0 0;
          }
        }
      }
    }

    .rate-other-option {
      .iata-switch {
        .switch {
          background: $neutralGrey1;
        }

        .checked {
          background: $primary;
        }
      }
    }


    .svg-radio-button-fill-stroke {
      svg {
        circle.outerRB {
          stroke: $primary;
        }

        circle.innerRB {
          fill: $primary;
        }
      }
    }

    .enhance-stay-bonus-point-container {
      .points-option:hover, .points-option.selected {
        border: 2px solid $primary;
      }
    }

    .container-points-options {
      .points-option:hover {
        border: 2px solid $primary;
      }
    }
  }
}

@each $brand, $color in $brandCodesColorMap {
  // @ihg/ui-components >> brands.scss
  @include theme(theme-#{$brand}, $color);
}

@each $brand, $color in $brandCodesButtonColorMap {
  .#{theme-#{$brand}} {

    .themeButtonBackground {
      background-color: $color;
    }
  }
}
