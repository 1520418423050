//Brand codes
$brandCodes: "ihg" "6c" "cp" "ki" "kd" "cv" "cw" "ex" "hi" "ic" "in" "ma" "rs" "sb" "ul" "vn" "sp" "nd" "ct" "va" "re" "vx" "sx";

// Brand Colors HEX
$ihg_brandColor: #C7370F; //UHF: #C7370F - 6c
$cp_brandColor: #60004E; //UHF: #60004E -
$ki_brandColor: #157DB4; //UHF #ff6646 -
$kd_brandColor: #000000; //kd hotels (Kimpton Club)
$cv_brandColor: #f47d30; //UHF: #ec6108 -
$cw_brandColor: #98002e; //UHF: #98002e -
$ex_brandColor: #002D72; //UHF: #0055a5 -//holidayinn express
$hi_brandColor: #216245; //UHF: #43a23b -
$ic_brandColor: #B38B7A; //UHF: #7b5400 -
$in_brandColor: #0E2E4F; //UHF: #0E2E4F -
$ma_brandColor: #E8542C; //UHF: #d96930 -
$rs_brandColor: #7C80C0; //UHF: #472d82 -
$sb_brandColor: #006980; //UHF: #78755a - //staybridge
$ul_brandColor: #d31245; //UHF: #b42d31 -
$vn_brandColor: #164A45; //UHF: #4297b5 - //even hotels
$sp_brandColor: #231F20; //UHF: #231F20 - //sp hotels
$nd_brandColor: #231F20;
$ct_brandColor: #231F20;
$va_brandColor: #25A1B2; //UHF: #25A1B2 - avid hotels
$vx_brandColor: #121212; //UHF: #121212 - voco
$re_brandColor: #003B49; //UHF: #003B49 - regent
$sx_brandColor: #6E3063; //UHF: #6E3063 - Six Senses
$we_brandColor: #A66659; //UHF: #943648 - Atwell suites
$lx_brandColor: #2E273B; //Vignette Collection
$sn_brandColor: #38A596; //sn hotels (Iberostar Beachfront Resorts)
$co_brandColor: #38A596; //co hotels (Iberostar Coral)
$gr_brandColor: #38A596; //gr hotels (Iberostar Grand)
$se_brandColor: #38A596; //se hotels (Iberostar Selection)
$ib_brandColor: #38A596; //ib hotels (Iberostar)
$rn_brandColor: #465D55; // Garner hotels
$nu_brandColor: #1C4B34; // New Wave

// Brand Color Map
$brandCodesColorMap: (
  "ihg": $ihg_brandColor,
  "6c": $ihg_brandColor,
  "cp": $cp_brandColor,
  "ki": $ki_brandColor,
  "kd": $kd_brandColor,
  "cv": $cv_brandColor,
  "cw": $cw_brandColor,
  "ex": $ex_brandColor,
  "hi": $hi_brandColor,
  "ic": $ic_brandColor,
  "in": $in_brandColor,
  "ma": $ma_brandColor,
  "rs": $rs_brandColor,
  "sb": $sb_brandColor,
  "ul": $ul_brandColor,
  "vn": $vn_brandColor,
  "sp": $sp_brandColor,
  "nd": $sp_brandColor,
  "ct": $sp_brandColor,
  "va": $va_brandColor,
  "vx": $vx_brandColor,
  "re": $re_brandColor,
  "sx": $sx_brandColor,
  "we": $we_brandColor,
  "lx": $lx_brandColor,
  "sn": $sn_brandColor,
  "co": $co_brandColor,
  "gr": $gr_brandColor,
  "se": $se_brandColor,
  "ib": $ib_brandColor,
  "rn": $rn_brandColor,
  "nu": $nu_brandColor,
);

$brandCodesButtonColorMap: (
  "ihg": #204357,
  "6c": #204357,
  "cp": #243D51,
  "ki": #33383F,
  "cv": #5B6770,
  "cw": #3B3B3B,
  "ex": #333333,
  "hi": #666666,
  "ic": #54534A,
  "in": #37424A,
  "ma": #204357,
  "rs": #5B6770,
  "sb": #51534A,
  "ul": #231815,
  "vn": #666666,
  "sp": #204357,
  "nd": #204357,
  "ct": #204357,
  "va": #3D3D3D,
  "vx": #73736F,
  "re": #000000,
  "sx": #204357,
  "we": #232323,
  "lx": #204357,
  "sn": #204357,
  "co": #204357,
  "gr": #204357,
  "se": #204357,
  "ib": #204357,
);

$ihg_height: 78px;

// List for pay-common.scss
$payCommonList: map-remove($brandCodesColorMap, "6c");

// List for _6c-variables.scss
$ramBrandRemoveList: "ihg" "6c" "nd" "ct";
$ramBrandColorList: map-remove($brandCodesColorMap, $ramBrandRemoveList...);

