/* IHG - Global Variables - UX Style Guide: Typography */
/* NOTE: Pulled from 'src\main\webapp\find-hotels\style\_globalVariables.scss' */

// color pallette
$ihgGrey: #707372;
$ihgLightGrey: #ebebeb;
$ihgMediumGrey: #53555b;
$ihgDarkGrey: #3f3d3d;
$ihgMango: #C7370F;
$ihgOriginal: #C7370F;
$ihgBlue: #204357;
$neutralGrey: #f4f2ed;
$neutralGrey1: #d8d3cb;
$neutralGrey2: #d8d3cb;
$neutralGrey3: #c0bab4;
$neutralGrey4: #b6aaad;
$neutralGrey5: #97999b;
$neutralGrey6: #e3e3e3;
$neutralGrey7: #ccc;
$neutralGrey8: #bfb8af;
$neutralGrey9: #C2C7CA;
$neutralGrey10: #555555;
$neutralGrey11: #333333;
$neutralGrey12: #EFEEEE;
$neutralGrey13: #5C5C5C;
$neutralGrey14: #DDDDDD;
$neutralGrey15: #B4B4B4;
$neutralGrey16: #787878;
$neutralGrey17: #909090;
$neutralGrey18: #A0A0A0;
$neutralGrey19: #6B6B6B;
$neutralGrey20: #767676;
$neutralGrey21: #8f9496;
$neutralGrey22: #ececec;
$neutralGrey23: #c0c0c0;
$neutralGrey24: #424040;
$neutralGrey25: #bdbdbd;
$neutralGrey26: #F0EEEE;
$neutralGrey27: #53555A;
$neutralGrey28: #393939;
$neutralGrey29: #9d9d9d;
$neutralGrey30: #e4e3df;
$neutralGrey31: #E5E5E5;
$neutralGrey32: #f4f4f4;
$neutralGrey33: #EDEDED;

$black: #000;
$black1: #0E0E0E;
$white: #fff;
$white1: #F0EEED;
$notificationBarText: #222;
$ribbonColor:#F8E20C;
$orange:#FFC400;
$darkBlueColor: #1F4456;
$lightBlueColor: #607985;
$holidayInnHoverGreen: #026048;

$primary-color: var(--global-brand-primary-color, $ihgMango);

// Typography
$typographyColor1: $white;
$typographyColor2: $ihgDarkGrey;
$typographyColor3: #f5f5f5;
$typographyColor4: #605d5d;
$typographyColor5: $ihgGrey;
$typographyColor6: #999999;
$typographyColor7: #fef6f6;
$typographyColor8: #d93932;
$typographyColor9: #bfb8af;
$typographyColor10: #55951b;
$typographyColor11: #D7D2CB;
$typographyColor12: #bbbbbb;

$notificationBarBackground: #f6f6f6;
$errorMessageBackground: #fee3a5;

// New SWAT Typography Color
$typographySwatColor2: #bfb8af;

$backgroundColor1: $ihgDarkGrey;

// Module Variables
$baseFontColor: $typographyColor4;
$secondaryFontColor: $white;
$baseFontSize: 14; // pixels
$disabled: $typographyColor6;

$baseBorderColor: $ihgGrey;
$hotelListBorderColor: $typographyColor9;
$bgColorHotelFlag: $typographyColor2;
$ctaAccent: $ihgMango;
$fieldOutlines: $ihgGrey;
$bookCTA_bgColorDefault: $black;
$bookCTA_opacity1: 0.16;
$bookCTA_opacity2: 0.95;
$ratingStarWidth: 98; // pixels
$ratingStarHeight: 20.9; // pixels
$ratingLineHeight: 20; // px
$secondaryBackgroundColor: $white;

$map_height_reduction: 15px;


// Font Family
$primaryFont: "Graphik";
$primaryFontLight: "Graphik-Light";
$primaryFontRegular: "Graphik-Regular";
$primaryFontMedium: "Graphik-Medium";
$primaryFontSemiBold: "Graphik-Semibold";
$primaryCanela: "Canela";
