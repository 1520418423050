/*!
 * IHG Style Imports - Find Hotels (following order):
 * - Variables:
 * -- @ihg/ui-components >> brands.scss
 * -- ihg-typography
 * -- ihg-bootstrap-overrides
 * - Mixins:
 * -- ihg-themes
 * - Bootstrap || Bootstrap Grid
 * - Hotel List - Common
 */

@import './variables';
@import './mixins';
@import './utils';

ihg-ui-room-remaining {
  --main-bg-color: var(--global-theme-button-secondary-bg, $ihgBlue);
}


:root {
  --swiper-pagination-color: $white;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-inactive-color: $neutralGrey9;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

@each $brand, $color in $payCommonList {
  .#{brand-bg-color-#{$brand}} {
    background-color: $color;
  }

  .#{brand-color-#{$brand}} {
    color: $color;
  }
}

body {
  background-color: $typographyColor3 !important;
}

body[dir="rtl"] {
  --direction: rtl;
}

body[dir="ltr"] {
  --direction: ltr;
}

// Customized Bootstrap imports
// Enable either Bootstrap Grid or Bootstrap
// @import './custom_bootstrap-grid';
@import './custom_bootstrap';

@import './custom-font-awesome';
@import "browser-support-notification";

// Common - Hotel List
@import './hotel-list-common';
