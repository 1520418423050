/* IHG - Bootstrap Variable Overrides */

// Bootstrap Grid breakpoints (min-width); used in: _custom_bootstrap-grid.scss
// BS Defaults:
// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px
$xs: 0;
$max-sm: 767px;
$sm: 768px;
$max-md: 991px;
$md: 992px;
$lg: 1200px;
$xl: 1800px;

// Bootstrap Container max widths; used in: _custom_bootstrap-grid.scss
// BS Defaults:
// sm: 540px,
// md: 720px,
// lg: 960px,
// xl: 1140px
$sm-max: 720px;
$md-max: 960px;
$lg-max: 1140px;
$xl-max: 1740px;

// Bootstrap overrides
$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl
) !default;

$container-max-widths: (
  sm: $sm-max,
  md: $md-max,
  lg: $lg-max,
  xl: $xl-max
) !default;
