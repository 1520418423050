/* IHG - Common Styles - Hotel List */
/* NOTE: Pulled from 'src\main\webapp\find-hotels\style\_search-results-common.scss' */

// UI Bootstrap Styling Fixes
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}
.bookingFlow {
  background-color: white;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Kimpton-specific styles
.kOne {
  font-family:"Proxima Nova", sans-serif;
  font-size: 3.05rem;
  line-height: 1.2em;
  font-weight: 400;
  letter-spacing: 0.011em;
  text-align: left;
  text-transform: capitalize;
  @media (min-width: 768px) {
    font-size: 6rem;
  }
  @media (min-width: 992px) {
    font-size: 6rem;
  }
}
.kTwo {
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  font-weight: lighter;
  letter-spacing: 0.011em;
  text-align: left;
  line-height: 1.2em;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
  @media (min-width: 992px) {
    font-size: 3rem;
  }
}
.kThree {
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  color: #53555b;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}
.kFour {
  font-family: proximanova, sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1em;
  color: #33383f;

  @media (min-width: 768px) {
    font-size: 32px;
    font-weight: normal;
  }
}
.kFive {
  color: #53555b;
  line-height: 1.6em;
  font-size: 13px;
  text-transform: uppercase;
  font-family: proximanova, sans-serif;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}
.kSix {
  font-weight: 600;
  line-height: 1em;
  font-size: 13px;
  text-transform: uppercase;
  font-family: proximanova, sans-serif;

  @media (min-width: 768px) {
    font-size: 15px;
    color: #131418;
  }
}
.kSeven {
  font-family: proximanova, sans-serif;
  line-height: 1.6em;
  color: #53555b;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}
.kEight {
  font-family: proximanova, sans-serif;
  line-height: 1.6em;
  color: #53555b;
  font-size: 14px;
}
.kNine {
  font-family: proximanova, sans-serif;
  line-height: 1.1em;
  font-size: 20px;
  color: #33383f;
  font-weight: lighter;

  @media (min-width: 768px) {
    font-size: 30px;
  }
}
.kTen {
  font-size: 30px;
  color: #31a7e6;
  line-height: 1em;
  font-family: oswald, sans-serif;
  font-weight: lighter;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 60px;
  }
}
.kEleven {
  font-family: proximanova, sans-serif;
  font-weight: 600;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}
.kTwelve {
  font-family: proximanova, sans-serif;
  font-size: 12px;
  line-height: 1.6em;
  color: #53555b;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 13px;
  }
}

// RBF style guide classes

//commented because new styles are introduced
// .hOne {
//   font-family: $primaryFontMedium, sans-serif;
//   font-weight: normal;
//   font-size: 24px;
//   line-height: 30px;
//   color: $ihgGrey;
//   font-style: normal;
// }

.hOne.kiFont {
   font-family: proximanova, Georgia, Serif;
   font-style: normal;
 }

.hOne.reFont {
  font-family: canela, Georgia, Serif;
  color: black;
  font-style: normal;
}

//commented because new styles are introduced
// .hTwo {
//   font-family: $primaryFontMedium, sans-serif;
//   font-size: 14px;
//   line-height: 18px;
//   font-weight: bold;
// }

//commented because new styles are introduced
// .hThree {
//   font-family: $primaryFontMedium, sans-serif;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 22px;
//   color: #fff;
//   font-style: normal;
// }
// .hFour {
//   font-family: $primaryFontRegular, sans-serif;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 22px;
//   color: #fff;
//   font-style: normal;
// }
.hFive {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.hSix {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  font-style: normal;
}
.hSeven {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.hEight {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.hNine {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.hTen {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.hEleven {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  font-style: normal;
}
.hTwelve {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.hThirteen {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.hFourteen {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 11px;
  line-height: 16px;
  color: $typographyColor2;
  font-weight: normal;
  font-style: normal;
}

.hFifteen {
  font-size: 18px;
  line-height: 24px;
  color: white;
  font-family: $primaryFontMedium, sans-serif;
}

.hSixteen {
  font-size: 14px;
  line-height: 18px;
  color: white;
  font-family: $primaryFontRegular, sans-serif;
}

.hSeventeen {
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.hEighteen,
.hNineteen {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-style: normal;
}

.hNineteen {
  color: $ihgDarkGrey;
}

.hTwenty {
  font-size: 20px;
  line-height: 26px;
  color: $typographyColor2;
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.hTwentyOne {
  font-size: 18px;
  line-height: 24px;
  color: $typographyColor2;
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.hTwentyTwo {
  font-size: 18px;
  line-height: 24px;
  color: $typographyColor1;
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.hTwentyThree {
  font-size: 16px;
  line-height: 22px;
  color: $neutralGrey5;
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.hTwentyFour {
  font-size: 16px;
  line-height: 22px;
  color: $typographyColor2;
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.hTwentyFive {
  font-size: 24px;
  line-height: 32px;
  color: $ihgDarkGrey;
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.hTwentySix {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-style: normal;
}

.hTwentySeven {
  font-size: 18px;
  line-height: 28px;
  color: $typographyColor2;
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.pSix {
     font-family: $primaryFontRegular, sans-serif;
     font-weight: normal;
     font-size: 12px;
     line-height: 16px;
     //color: #fff;
     font-style: normal;
}
.pEight {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  //color: ;
  font-style: normal;
}
.pNine {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  //color: ;
  font-style: normal;
}
.pTen {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #cc0000;
  font-style: normal;
}
.pEleven {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  font-style: normal;
}
.pTwelve {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.pThirteen {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $ihgDarkGrey;
  font-style: normal;
}

.pFourteen {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 11px;
  line-height: 17px;
  color: $ihgDarkGrey;
  font-weight: normal;
  font-style: normal;
}

.pFifteen {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 11px;
  line-height: 17px;
  color: $ihgGrey;
  font-weight: normal;
  font-style: normal;
}

.pSixteen {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $ihg_brandColor;
}
.pSeventeen {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  color: $ihgDarkGrey;
}
.pEighteen {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  font-style: normal;
}
.pNineteen {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $ihgDarkGrey;
}
.pTwenty {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $typographyColor1;
}
.pTwentyOne {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.pTwentyTwo {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
}
.pTwentyThree {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
}
.pTwentyFour {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $typographyColor2;
  font-style: normal;
}
.pTwentyFive {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $typographyColor10;
  font-style: normal;
}
.pTwentySix {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $typographyColor1;
  font-style: normal;
}
.pTwentySeven {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $neutralGrey5;
  font-style: normal;
}
.pTwentyEight {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $neutralGrey5;
  font-style: normal;
}
.pTwentyNine {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $neutralGrey5;
  font-style: normal;
}
.pThirty {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.pThirtyBold {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.pThirtyOne {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.pThirtyFour {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: $typographyColor2;
  font-style: normal;
}

.pThirtyFive {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 15px;
  line-height: 25px;
  color: $ihgGrey;
  font-weight: normal;
  font-style: normal;
}
.pThirtySix {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: $ihgDarkGrey;
  font-weight: normal;
  font-style: normal;
}

.pThirtySixBold {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: $ihgDarkGrey;
  font-weight: bold;
  font-style: normal;
}

.pThirtySeven {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-weight: normal;
  font-style: normal;
}
.vertical-text {
  display: table-cell;
  vertical-align: middle;
}

.sOne {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.sTwo {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $neutralGrey3;
  font-style: normal;
}
.sThree {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: #fff;
  font-style: normal;
}
.sFour {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  color: #fff;
  font-style: normal;
}
.sFive {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.sSix {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $ihgDarkGrey;
}
.sSeven {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: $ihgDarkGrey;
}

.sEleven {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.sTwelve {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.sThirteen {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;
  color: $ihgDarkGrey;
  font-style: normal;
}

.sFifteen {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 7px;
  line-height: 13px;
  color: $ihgDarkGrey;
  font-style: normal;
}

.sSixteen {
  font-family: $primaryFontSemiBold, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: $ihgDarkGrey;
  font-weight: normal;
  font-style: normal;
}
.sSeventeen {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $ihgDarkGrey;
  font-style: normal;
}

.sEighteen {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $typographyColor2;
  font-style: normal;
}
.sNineteen {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: $typographyColor2;
  font-style: normal;
}
.sTwenty {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: $typographyColor1;
  font-style: normal;
}
.sTwentyOne {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: $neutralGrey5;
  font-style: normal;
}
.sTwentyTwo {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $neutralGrey5;
  font-style: normal;
}
.sTwentyThree {
  font-family: $primaryFontMedium, sans-serif;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: $neutralGrey5;
  font-style: normal;
}
.sTwentyFour {
  font-family: $primaryFontSemiBold, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $neutralGrey5;
  font-style: normal;
}
.sTwentyFive {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $typographyColor2;
  font-style: normal;
}
.sTwentySix {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $typographyColor2;
  font-style: normal;
}

.aOne {
  font-family: $primaryFontRegular, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: $ihgGrey;
  font-size: 14px;
  line-height: 20px;
}
.aTwo {
  font-family: $primaryFontLight, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $ihgDarkGrey;
  font-style: normal;
}
.aThree {
  font-family: $primaryFontLight, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: $typographyColor1;
  font-size: 14px;
  line-height: 20px;
}
.aFour {
  font-family: $primaryFontLight, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: $ihgGrey;
  font-size: 10px;
  line-height: 16px;
}
.aOne {
}
.aTwo {
}
.arrowOffsite {
  position: absolute;
  height: 4%;
  width: 4%;
  z-index: 5;
  top: 0;
  right: 0;
}
.arrowOffsiteContainer {
  position: relative;
}
.kimptonAvailabilityStylesInner {
  width: 100%;
  height: 20px;
}
.kimptonAvailabilityStylesDesc {
  font-size: 14px;
  line-height: 18px;
}

.hOne {
  font-family: $primaryFontLight, sans-serif;
  font-size: 60px;
  line-height: 66px;
  color: $ihgMediumGrey;
  font-style: normal;
  @media (max-width: $sm) {
    font-size: 50px;
    line-height: 60px;
  }
}

.hTwo {
  font-family: $primaryFontLight, sans-serif;
  font-size: 50px;
  line-height: 56px;
  color: $ihgMediumGrey;
  font-style: normal;
  @media (max-width: $sm) {
    font-size: 40px;
    line-height: 50px;
  }
}

.hThree {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 40px;
  line-height: 46px;
  color: $ihgMediumGrey;
  font-style: normal;
  @media (max-width: $sm) {
    font-size: 30px;
    line-height: 40px;
  }
}

.hFour {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 30px;
  line-height: 36px;
  color: $ihgMediumGrey;
  font-style: normal;
  @media (max-width: $sm) {
    font-size: 26px;
    line-height: 36px;
  }
}

.hFourBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 30px;
  line-height: 36px;
  color: $ihgMediumGrey;
  font-style: normal;
  @media (max-width: $sm) {
    font-size: 26px;
    line-height: 36px;
  }
}

.hFiveBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 26px;
  line-height: 24px;
  color: $ihgDarkGrey;
  font-style: normal;
}

.hSixBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: $ihgDarkGrey;
  font-style: normal;
}

.pOne {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pOneBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pTwo {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 22px;
  line-height: 28px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pTwoBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 22px;
  line-height: 28px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pThree {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pThreeLight {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 14px;
  line-height: 26px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pThreeBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pFour {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pFourLight {
  font-family: $primaryFontLight, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: $ihgGrey;
  font-style: normal;
}

.pFourBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.lineThrough {
  text-decoration: line-through;
}

.pFive {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pFiveBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pSix {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pSixRegular {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 11px;
  line-height: 16px;
  color: $neutralGrey10;
  font-style: normal;
}

.pSixBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pSixSemiBold {
  font-family: $primaryFontSemiBold, sans-serif;
  font-size: 20px;
  line-height: 20px;
  color: $ihgDarkGrey;
  font-style: normal;
  @media (min-width: $md) {
    font-size: 24px;
  }
}

.pSeven {
  font-family: $primaryFontRegular, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: $ihgMediumGrey;
  font-style: normal;
}

.pSevenBold {
  font-family: $primaryFontMedium, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: $ihgMediumGrey;
  font-style: normal;
}

@media (min-width: 992px) {
  // RBF style guide classes
  .hOne {
    font-size: 38px;
    line-height: 46px;
    color:$ihgGrey;
    font-family: Graphik-Medium,sans-serif;
    font-weight:400;
  }
  .hTwo {
    font-size: 18px;
    line-height: 23px;
  }
  .hFour {
    font-size: 18px;
    line-height: 24px;
  }
  .hFive {
    font-size: 18px;
    line-height: 24px;
  }
  .hSix {
    font-size: 16px;
    line-height: 22px;
  }
  .hSeven {
    font-size: 16px;
    line-height: 22px;
  }
  .hEight {
    font-size: 18px;
    line-height: 24px;
  }
  .hNine {
    font-size: 18px;
    line-height: 24px;
  }
  .hTen {
    font-size: 18px;
    line-height: 24px;
  }
  .hEleven {
    font-size: 18px;
    line-height: 24px;
  }
  .hTwelve {
    font-size: 18px;
    line-height: 24px;
  }
  .hThirteen {
    font-size: 14px;
    line-height: 20px;
  }
  .hFourteen {
  }

  .hFifteen {
    font-size: 28px;
    line-height: 34px;
  }

  .hSixteen {
    font-size: 23px;
    line-height: 29px;
  }

  .hSeventeen {
    font-size: 27px;
    line-height: 33px;
  }

  .hEighteen,
  .hNineteen {
    font-size: 20px;
    line-height: 26px;
  }

  .hTwenty {
    font-size: 24px;
    line-height: 30px;
  }

  .hTwentyOne {
    font-size: 20px;
    line-height: 26px;
  }

  .hTwentyTwo {
    font-size: 20px;
    line-height: 26px;
  }

  .hTwentyThree {
    font-size: 18px;
    line-height: 24px;
  }

  .hTwentyFour {
    font-size: 20px;
    line-height: 26px;
  }

  .hTwentyFive {
    font-size: 24px;
    line-height: 36px;
  }

  .hTwentySix {
    font-size: 16px;
    line-height: 26px;
  }

  .hTwentySeven {
    font-size: 18px;
    line-height: 28px;
  }

  .pOne {
    font-size: 18px;
    line-height: 24px;
  }
  .pTwo {
    font-size: 16px;
    line-height: 22px;
  }
  .pThree {
    font-size: 16px;
    line-height: 22px;
  }
  .pFour {
    font-size: 18px;
    line-height: 24px;
  }
  .pFive {
    font-size: 16px;
    line-height: 22px;
  }
  .pSix {
    font-size: 14px;
    line-height: 16px;
  }
  .pSeven {
    font-size: 18px;
    line-height: 24px;
  }
  .pEight {
    font-size: 18px;
    line-height: 24px;
  }
  .pNine {
    font-size: 16px;
    line-height: 22px;
  }
  .pTen {
    font-size: 14px;
    line-height: 16px;
  }
  .pEleven {
    font-size: 14px;
    line-height: 16px;
  }
  .pTwelve {
    font-size: 16px;
    line-height: 22px;
  }
  .pThirteen {
    font-size: 16px;
    line-height: 22px;
  }
  .pFourteen {
    font-size: 11px;
    line-height: 17px;
  }

  .pFifteen {
    font-size: 13px;
    line-height: 19px;
  }

  .pSixteen {
    line-height: 25px;
  }

  .pSeventeen {
    font-size: 14px;
    line-height: 20px;
  }

  .pEighteen {
    font-size: 14px;
    line-height: 16px;
  }
  .pNineteen {
    font-size: 16px;
    line-height: 22px;
  }
  .pTwenty {
    font-size: 16px;
    line-height: 22px;
  }
  .pTwentyOne {
    font-size: 14px;
    line-height: 20px;
  }
  .pTwentyTwo {
    font-size: 14px;
    line-height: 20px;
  }
  .pTwentyThree {
    font-size: 14px;
    line-height: 20px;
  }
  .pTwentyFour {
    font-size: 14px;
    line-height: 20px;
  }
  .pTwentyFive {
    font-size: 16px;
    line-height: 22px;
  }
  .pTwentySix {
    font-size: 16px;
    line-height: 22px;
  }
  .pTwentySeven {
    font-size: 18px;
    line-height: 24px;
  }
  .pTwentyEight {
    font-size: 14px;
    line-height: 20px;
  }
  .pTwentyNine {
    font-size: 16px;
    line-height: 22px;
  }
  .pThirty {
    font-size: 16px;
    line-height: 26px;
  }
  .pThirtyOne {
    font-size: 14px;
    line-height: 24px;
  }
  .pThirtyFour {
    font-size: 12px;
    line-height: 13px;
  }
  .sOne {
    font-size: 24px;
    line-height: 24px;
  }
  .sTwo {
    font-size: 20px;
    line-height: 24px;
  }
  .sThree {
    font-size: 40px;
    line-height: 40px;
  }
  .sFour {
    font-size: 13px;
    line-height: 14px;
  }
  .sFive {
    font-size: 27px;
    line-height: 33px;
  }
  .sSix {
    font-size: 12px;
    line-height: 18px;
  }
  .sSeven {
    font-size: 20px;
    line-height: 26px;
  }
  .sEleven {
    font-size: 16px;
    line-height: 22px;
  }
  .sTwelve {
    font-size: 16px;
    line-height: 18px;
  }
  .sThirteen {
    font-size: 14px;
    line-height: 29px;
  }
  .sFifteen {
    font-size: 7px;
    line-height: 13px;
  }
  .sSixteen {
    font-size: 16px;
    line-height: 22px;
  }
  .sSeventeen {
    font-size: 11px;
    line-height: 17px;
  }
  .sEighteen {
    font-size: 18px;
    line-height: 24px;
  }
  .sNineteen {
    font-size: 18px;
    line-height: 24px;
  }
  .sTwenty {
    font-size: 27px;
    line-height: 33px;
  }
  .sTwentyOne {
    font-size: 27px;
    line-height: 33px;
  }
  .sTwentyTwo {
    font-size: 18px;
    line-height: 24px;
  }
  .sTwentyThree {
    font-size: 12px;
    line-height: 18px;
  }
  .sTwentyFour {
    font-size: 18px;
    line-height: 24px;
  }
  .sTwentyFive {
    font-size: 16px;
    line-height: 22px;
  }
  .sTwentySix {
    font-size: 16px;
    line-height: 22px;
  }

  .aOne {
    font-size: 14px;
    line-height: 20px;
  }
  .aTwo {
    font-size: 18px;
    line-height: 24px;
  }
  .aThree {
    font-size: 14px;
    line-height: 20px;
  }
  .aFour {
    font-size: 10px;
    line-height: 16px;
  }
}

.lineThrough {
  text-decoration: line-through;
}

.lowestPointsOriginalCash {
  padding-left: 2px;
  padding-right: 2px;
}

.displayBlock {
  display: inline-block;
}

.searchResultsContainer {
  direction: ltr;
}

.nopadding {
  padding: 0 !important;
}
.nopadding-left {
  padding-left: 0 !important;
}

a:link,
a:visited,
a:hover,
a:active {
  color: $typographyColor5;
}

@each $brand, $color in $payCommonList {
  // @ihg/ui-components >> brands.scss
  @if $brand == "ihg" {
    .brand {
      color: $color;
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: $color;
      }
      .brand-text {
        color: $color;
      }
      .brand-text-hover:hover {
        color: $color;
      }
      .brand-border {
        border: 1px solid $color;
      }
      .brand-outline{
        outline: 1px solid $color
      }
      .brand-background {
        background-color: $color;
      }
      .brand-highlight, .brand-hover:hover {
        background-color: $color;
        color: $white;
      }
    }
  } @else {
    .brand--#{$brand} {
      color: $color;
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: $color;
      }

      .brand-text {
        color: $color;
      }
      .brand-border {
        border: 1px solid $color;
      }
      .brand-outline{
        outline: 1px solid $color
      }
      .brand-background {
        background-color: $color;
      }
      .brand-highlight, .brand-hover:hover {
      background-color: $color;
      color: $white;
      }
    }
  }
}

.ratingContainer {
  white-space: normal;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.ratingTextContainer,
.ratingStarsContainer {
  //vertical-align: middle;
  display: inline-block;
}
.ratingStarsContainer {
  width: $ratingStarWidth + px;
  //height: $ratingStarHeight+px;
  height: 22px;
  position: relative;
  margin-right: 8px;
}
.rating-stars {
  position: absolute;
  background-image: url("/cdn/vendor/ihgimages/v1.0.0/rbf-stars-fill.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: $ratingStarWidth + px 100%;
  display: inline-block;
  vertical-align: text-bottom;
  width: 100%;
  top: 0;
  bottom: 0;
  opacity: 0.7;
}

.rating-stars-outline {
  position: absolute;
  background-image: url("/cdn/vendor/ihgimages/v1.0.0/rbf-stars-outline.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: $ratingStarWidth + px 100%;
  display: inline-block;
  vertical-align: text-bottom;
  width: 100%;
  top: 0;
  bottom: 0;
}

[dir="rtl"] .rating-stars-outline,
[dir="rtl"] .rating-stars {
  transform: scaleX(-1);
}

[dir="rtl"] .rating-stars {
  left: -1px;
}

[dir="rtl"] .rateIconContainer {
  transform: scaleX(-1);
}

.rating-reviews-link,
.rating-reviews-link:hover,
.rating-reviews-link:active,
.rating-reviews-link:link,
.rating-reviews-link:visited {
  color: inherit;
  border-bottom: 1px solid #000;
  text-decoration: none;
}

/*waiting overlay*/
.waiting-overlay {
  min-height: 450px;
  width: 100%;

  &.transition-in {
    animation: waiting-fade-in-with-delay 1.2s ease-in;
    min-height: 200px;
  }

  .spinner {
    font-size: 70px;
    color: $neutralGrey2;
    width: 100%;
    text-align: center;
    position: relative;
    transform: none;
  }
}

.hotel-rooms-container,
.hotel-rates-container,
.hotel-list-container {
  .waiting-overlay {
    padding-top: 10px;
  }
}

@keyframes waiting-fade-in-with-delay {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

//if any of these common styles for this directive need changed please do it by overriding the styles in page css
.sized-text-container {
  width: 100%;
  height: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;

  .sized-text {
    display: flex;
    line-height: 1em;
    align-items: left;
  }

  .sized-text-cents {
    font-size: 50%;
    line-height: 1em;
    padding-left: 0.1em;
    padding-top: 0.25em;
  }
}

//if any of these common styles for this directive need changed please do it by overriding the styles in page css
.shrinker-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .shrinker {
    white-space: nowrap;
  }

  .shrinker * {
    display: inline-block;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .shrinker * {
      transform-origin: center left;
    }
  }
  @supports (-ms-accelerator: true) {
    /* IE Edge 12+ CSS styles go here */
    .shrinker * {
      transform-origin: center left;
    }
  }
}
.overlayContainer {
  .hotelDetailsPopupContainer {
    background-color: $typographyColor1;
    border: 1px solid $ihgGrey;
    border-radius: 5px;
    max-width: 1090px;
    width: calc(100% - 50px);

    @media (min-width: 768px) {
      width: calc(100% - 80px);
    }

    @media (min-width: 992px) {
      width: calc(100% - 90px);
    }

    @media (min-width: 1200px) {
      width: calc(100% - 110px);
    }
  }
  .xsHotelDetailsInfo {
    display: inline-block;
    margin: 5px 15px;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.theme-dropdown {
  .ui-dropdown {
    border: 1px solid $neutralGrey9;
    padding-top: 2px;
    border-radius: 5px;
    height: 40px;
    min-width: 0;

    .ui-dropdown-trigger {
      visibility: hidden;
    }

    .ui-dropdown-label-container {
      .ui-dropdown-label {
        color: $neutralGrey10;
        font-family: primaryFontRegular,sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        padding-left: 15px;
        padding-right: 12px;
        text-align: center;
        display: flex;
        gap: 8px;
      }
      .ui-dropdown-label::after {
        background-color: $neutralGrey10;
        content: '';
        display: inline-block;
        mask-image: url('/cdn/vendor/ihgimages/v1.0.0/caret-down.svg');
        mask-size: 15px 15px;
        mask-repeat: no-repeat;
        mask-position: bottom;
        position: relative;
        top: 3px;
        height: 15px;
        width: 15px;
      }
    }

    .ui-dropdown-panel {
      border: none;
      box-shadow: none;
      top: 3px !important;
      position: relative;

      .ui-dropdown-items-wrapper {
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15), -4px 0px 8px rgba(0, 0, 0, 0.15);
        clip-path: inset(-1px -12px -12px -12px);

        hr {
          margin: 0;
          height: 1px;
          border-bottom: 1px solid $neutralGrey10;
        }
      }
    }

    .ui-dropdown-items {
      p-dropdownitem .ui-dropdown-item {
        color: $black;
        font-family: $primaryFontRegular,sans-serif;
        font-size: 16px;
        height: 40px;
        line-height: 20px;
        padding: 0 6px;
        white-space: nowrap;

        span {
          border-bottom: 1px solid $neutralGrey9;
          display: inline-block;
          padding: 10px 8px;
          width: 100%;

          [dir="rtl"] & {
            text-align: right;
          }
        }
      }

      p-dropdownitem:last-child .ui-dropdown-item {
        span {
          border: none;
        }
      }

      .ui-dropdown-item.ui-state-highlight {
        background-color: $ihgOriginal;
        color: $white;
        font-family: $primaryFontMedium,sans-serif;
        span {
          border-bottom: none;
        }
      }
    }
  }

  .ui-dropdown.ui-dropdown-open {
    .ui-dropdown-label-container {
      .ui-dropdown-label::after {
        transform: rotate(180deg);
      }
    }
  }

  .ui-dropdown:not(.ui-state-disabled):hover {
    border-color: $neutralGrey9;
  }

  .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    border-color: $neutralGrey9;
  }

  .ui-dropdown:not(.ui-state-disabled).ui-dropdown-open {
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15), -4px 0px 8px rgba(0, 0, 0, 0.15);
  }
}

.luxury-crr-dropdown {
  .ui-dropdown  {
    background: transparent;
    border: none;
    min-width: 4em;
    .ui-state-focus {
      border: none !important;
    }
   .ui-dropdown-panel {
      left: -12px !important;
      top: 26px !important;
    }
    .ui-dropdown-label {
      font-family: $primaryFontRegular;
      background: transparent;
      padding-bottom: 0;
      padding-left: 0;
      padding-top: 0;
      color: $black !important;
      [dir="rtl"] & {
        padding-right: 10px;
      }
    }
    .ui-dropdown-trigger {
      background-color: transparent;
      [dir="rtl"] & {
        left: 0;
        right: inherit;
      }
      .ui-dropdown-trigger-icon {
        color: $black;
        font-size: 15px;
        font-weight: bold;
        top: 45%;
        [dir="rtl"] & {
          left: 0;
        }
      }
    }
  }
  
}
.crr-dropdown {
  .ui-dropdown {
    min-width: 6em;
  }

  .ui-dropdown.ui-dropdown-open {
    .ui-corner-right {
      .ui-dropdown-trigger-icon::before {
        content: '\e903';
      }
    }
  }

  .ui-dropdown .ui-dropdown-trigger-icon {
    color: $darkBlueColor !important;
  }

  .ui-dropdown .ui-dropdown-label {
    font-weight: normal;
    color: $darkBlueColor !important;
  }

  .ui-dropdown {
    border-color: $neutralGrey9 !important;
  }

  .ui-dropdown:hover {
    border-color: $neutralGrey9 !important;
  }

  .ui-dropdown.ui-state-focus {
    border: 2px solid $black !important;
    border-radius: 5px;
  }

  .ui-state-highlight {
    background-color: $neutralGrey21 !important;
  }
}

//This will allow you to have a sizeable, brand-themed, custom radio button using pseudo-elements.
//It places the radio button over the actual radio. Then it's linked to it using the label.
//The element structure is:
// .themeRadio
//    <input type="radio">
//    <label for="" class="radio-label"> //The radio overlay is contained in the label
//        <div class="radio-label-text">
.themeRadio {
  @media (min-width: 767px) {
    margin: 0 0 15px 0;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 0 0 20px 0;
  }
  margin: 0 0 17px 0;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    margin: 0;
    height: 30px; //size of the radio buttons
    width: 30px;
    + .radio-label {
      display: flex;
      align-items: center;
      padding-left: 0;
      &:before {
        content: "";
        background: white;
        border-radius: 100%;
        border: 1px solid darken($ihgDarkGrey, 25%);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          //sets the properties of the selected radio
          box-shadow: inset 0 0 0 3px white;
        }
      }
    }
  }
}

.rate-availability-label {
  border-radius: 3px;
  background-color: $typographyColor3;
  color: $darkBlueColor;
  font-family: $primaryFontRegular;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
  padding: 10px;

  .rate-availability-label-bold {
    font-family: $primaryFontSemiBold;
  }
}

.overlayContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 50001;
  left: 0;
  top: 0;
  background-color: rgba(112, 115, 114, 0.9);
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.format-price-ar {
  position: absolute;
  margin-left: -20px;
}

.btn-reset {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.exception-notification {
  .error-notifications {
    .content {
      max-width: 1004px !important;
      padding: 20px !important;
      @media (max-width: $md) {
        padding: 20px 15px !important;
      }
      .warning-messages-wrapper{
        align-items: center;
        gap: 22px !important;
        padding-right: 34px;
      }
      svg{
        width:30px !important;
        height:30px !important;
      }
    }
    .message {
      font-family: $primaryFontRegular, sans-serif !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 21px !important;
      .resConErrorLink {
        text-decoration: underline;
        color: $black;
      }
    }
  }
}

[dir="rtl"] .extra-amenities-container {
  direction: ltr;
}

[dir="rtl"] .hotelInfoSummary,
[dir="rtl"] .room-rate-summary-container,
[dir="rtl"] .hotelInfoSummary,
[dir="rtl"] .mms-keep_width,
[dir="rtl"] .page-title,
[dir="rtl"] .dph-header {
  text-align: right;
}


[dir="rtl"] .hotel-brand-icon-header,
[dir="rtl"] .hotel-address-reviews-container {
    margin-right: 25px;
}

[dir="rtl"] .hotel-details {
  border-right: none;
}

[dir="rtl"] .right-boarder {
    border-left: 1px solid $typographyColor5;
}

.sr-filter {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
 }

ihg-ui-svg-loader {
  #svgContainer {
    display: flex;
  }
}

.dark-blue-svg {
  svg {
    fill: $darkBlueColor;
  }
}